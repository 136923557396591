import styled from "styled-components";
import { Box } from "@mui/material";

export const Container = styled(Box)`
	width: 100%;
`;

export const ContainerChild = styled(Box)`
	width: 80%;
	margin: 0 auto;
	@media only screen and (max-width: 480px) {
		width: 95%;
		margin: 0 auto;
	}
`;

export const CareersDiv = styled(Box)`
	width: 100%;
	margin: 2rem 0 5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.MuiPaper-root {
		width: 27%;
		padding: 1rem;
		margin: 1rem 0;
		text-align: start;
		.MuiTypography-root.MuiTypography-h6 {
			font-weight: bold;
			color: #263238;
		}
		.MuiTypography-root.MuiTypography-body1 {
			font-size: 0.9rem;
		}
		div {
			width: 100%;
			text-align: end;
			.MuiButton-root.MuiButton-text {
				margin-top: 0.5rem;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.MuiPaper-root {
			width: 42%;
		}
	}
	@media only screen and (max-width: 480px) {
		justify-content: center;
		.MuiPaper-root {
			width: 85%;
			padding: 1rem 0.8rem;
			.MuiTypography-root.MuiTypography-body1 {
				font-size: 0.85rem;
			}
		}
	}
`;
