import { useEffect } from "react";
import {
	Main,
	MainButton,
	MainButtonBox,
	Mainchild,
	MiniChild,
	PricingBox,
	TextBox,
} from "./Pricing.style";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { Pricing as Price } from "../../components/pricing/Pricing";

import Typography from "@mui/material/Typography";
// import line from "../../images/Line 4.png";
// import ellipse from "../../images/Ellipse 66.png";
import rectangle from "../../images/Rectangle 116.png";
import rect from "../../images/Rectangle 117.png";
import play from "../../images/play-button-svgrepo-com.png";

import { Footer } from "../../components/footer/Footer";
import { Feedback } from "../../components/demo-feedback/Feedback";
export const Pricing = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<PricingBox>
			<Navbar />
			<Main>
				<MiniChild>
					<Mainchild>
						<img src={rectangle} alt="rectangle" className="rectangle" />
						<TextBox>
							<Typography variant="body1">
								The pricing suits everyone at their capacity
							</Typography>
							<Typography variant="h4">Pricing</Typography>
							<Typography variant="body2">
								Connect securely, browse seamlessly, design conveniently, write
								query like a pro
							</Typography>
						</TextBox>
						<MainButtonBox>
							<MainButton
								variant="contained"
								style={{ backgroundColor: "#64b5f6" }}
							>
								Live Demo
							</MainButton>
							<MainButton variant="contained">
								{" "}
								<Link to="/signup">Sign Up</Link>
							</MainButton>
							<MainButton variant="outlined">
								<img src={play} alt="play" />
								Watch Illustration
							</MainButton>
						</MainButtonBox>
					</Mainchild>
					<Price />
				</MiniChild>
				<Feedback />
				<img src={rectangle} alt="rectangle" className="rect" />
				<img src={rect} alt="rectangle" className="angle" />
			</Main>
			<Footer />
		</PricingBox>
	);
};
