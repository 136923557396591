import {
	ImageBox,
	SectionBody,
	SectionBodyChildA,
	SectionBodyChildB,
	SectionBox,
	SectionHead,
	SectionHeadChild,
	TypographyContainer,
} from "./Normal.style";

// import line from "../../images/Line 4.png";
import rect from "../../images/Rectangle 335.png";
import rec from "../../images/Rectangle 117.png";
import Typography from "@mui/material/Typography";
export const Normal = (props) => {
	return (
		<SectionBox>
			<SectionHead>
				<SectionHeadChild>
					{/* <img src={line} alt="divider" /> */}
					<Typography variant="h4">
						{props.heading1}{" "}
						<Typography variant="h4">{props.heading2}</Typography>
					</Typography>
				</SectionHeadChild>
			</SectionHead>
			<SectionBody>
				<SectionBodyChildA>
					<ImageBox>
						<img src={props.image} alt="child" className="img" />
					</ImageBox>
					<img src={rect} alt="rect" className="rect" />
				</SectionBodyChildA>
				<SectionBodyChildB>
					<img src={rec} alt="rec" className="rec" />
					<TypographyContainer>
						<Typography variant="body1">{props.text1}</Typography>
						<Typography variant="body1">{props.text2}</Typography>
						<Typography variant="body1">{props.text3}</Typography>
					</TypographyContainer>
				</SectionBodyChildB>
			</SectionBody>
		</SectionBox>
	);
};
