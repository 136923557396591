import styled from "styled-components";
import Box from "@mui/material/Box";

export const SectionBox = styled(Box)`
	width: 100%;
	height: 80%;
	margin: 3rem 0;
	@media only screen and (max-width: 768px) {
		margin: 1rem 0;
	}
`;

export const SectionHead = styled(Box)`
	width: 100%;
	height: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 5;
`;
export const SectionHeadChild = styled(Box)`
	width: 50%;
	text-align: center;
	.MuiTypography-root.MuiTypography-h4 {
		font-size: 2.5rem;
		letter-spacing: 0px;
		color: #23233c;
		font-family: "Open Sans", sans-serif;
		font-weight: bold;
		margin: 1rem 0 3rem 0;
		.MuiTypography-root.MuiTypography-h4 {
			font-family: "Open Sans", sans-serif;
			letter-spacing: 0px;
			color: #1283ed;
			display: inline-block;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 90%;
		font-size: 2rem;
		.MuiTypography-root.MuiTypography-h4 {
			margin: 1rem 0;
		}
	}
`;

export const SectionBody = styled(Box)`
	width: 100%;
	height: 80%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const SectionBodyChildA = styled(Box)`
	width: 45%;
	position: relative;

	.ellipse {
		position: absolute;
		top: -55%;
		right: -30%;
		width: 50%;
	}
	@media only screen and (max-width: 768px) {
		width: 90%;
		margin: 1rem auto;
	}
`;
export const ImageBox = styled(Box)`
	width: 100%;
	position: relative;
	z-index: 2;
	img {
		width: 100%;
		height: 80%;
	}
`;

export const SectionBodyChildB = styled(Box)`
	width: 50%;
	position: relative;
	text-align: start;
	.rect {
		position: absolute;
		top: -35%;
		left: -50%;
		width: 100%;
	}
	@media only screen and (max-width: 768px) {
		width: 90%;
		margin: 1rem auto;
	}
`;
export const TypographyContainer = styled(Box)`
	width: 85%;
	position: relative;
	z-index: 2;
	text-align: justify;
	text-justify: inter-word;
	.MuiTypography-root.MuiTypography-body1 {
		letter-spacing: 0px;
		color: #7f8da0;
		font-size: 1.08rem;
		font-weight: 400;
		margin: 1rem 0;
		&:first-of-type {
			margin: 0;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 100%;
	}
`;
