import styled from "styled-components";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";

export const PricingBox = styled(Box)`
	width: 100%;
	height: 80%;
	margin: 3rem 0;
	position: relative;
	z-index: 2;
`;

export const PricingHead = styled(Box)`
	width: 100%;
	height: 20%;
	text-align: center;
	margin: 0 0 4rem 0;
	.MuiTypography-root.MuiTypography-h4 {
		font-size: 2.8rem;
		letter-spacing: 0px;
		color: #23233c;
		opacity: 1;
		font-weight: bold;
		font-family: "Open Sans", sans-serif;
	}
	.MuiTypography-root.MuiTypography-body1 {
		font-size: 1.2rem;
		letter-spacing: 0px;
		color: #7f8da0;
		font-weight: 400;
	}
`;

export const PricingBody = styled(Box)`
	width: 100%;
	height: 80%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

export const PricingBodyChild = styled(Box)`
	width: 21.5%;
	min-height: 100%;
	border: 2px solid #bdbdbd;
	border-radius: 8px;
	text-align: center;
	padding: 1rem;
	position: relative;
	.MuiButtonBase-root.MuiButton-root {
		margin: 0.5rem auto;
		text-transform: none;
		color: #263238;
		font-weight: 500;
	}
	@media only screen and (min-width: 1024px) and (max-width: 1240px) {
		width: 28%;
		margin: 1rem 0;
	}
	@media only screen and (max-width: 768px) {
		width: 43%;
		margin: 1rem 0;
	}
	@media only screen and (max-width: 766px) {
		width: 80%;
		margin: 1rem auto;
	}
`;

export const ChildHead = styled(Box)`
	width: 100%;
	border-bottom: 1px solid #fafafa;

	.MuiTypography-root.MuiTypography-h5 {
		letter-spacing: 0px;
		color: #000000;
		font-weight: bold;
		font-size: 1.4rem;
		margin-bottom: 1rem;
	}
	.MuiTypography-root.MuiTypography-body1 {
		font-family: "Poppins", sans-serif;
		font-size: 0.8rem;
		font-weight: 300;
		letter-spacing: 0px;
		color: #7a839e;
		display: block;
		min-height: 2.5rem;
	}
`;

export const ChildMain = styled(Box)`
	width: 100%;
	border-bottom: 1px solid #bdbdbd;
	text-align: center;
	.MuiTypography-root.MuiTypography-body2 {
		font-size: 1rem;
		font-family: "Poppins", sans-serif;
		color: #7a839e;
		font-weight: 300;
		letter-spacing: 0px;
		margin-bottom: 0.5rem;
	}
`;

export const DifText = styled(Typography)`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
`;
export const PriceBox = styled(Box)`
	width: 130px;
	height: 80px;
	margin: 1rem auto;
	border-radius: 50%;
	background: #1283ed;
	color: #ffffff;
	padding: 1.5rem 0;
	
`;

export const ChildBody = styled(Box)`
	width: 100%;
`;
export const InfoBox = styled(Box)`
	width: 90%;
	min-height: 80%;
	margin: 1rem auto 3rem;
`;
export const Info = styled(Box)`
	width: 100%;
	display: flex;
	margin: 0.5rem 0;
	.MuiSvgIcon-root {
		width: 1rem;
		height: 1rem;
		fill: #00c853;
		margin-right: 0.2rem;
	}
	.MuiTypography-root.MuiTypography-body1 {
		font-size: 0.8rem;
		font-family: "Poppins", sans-serif;
		color: #7a839e;
		font-weight: 300;
		letter-spacing: 0px;
		text-align: start;
	}
`;
export const PriceButtonBox = styled(Box)`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 1%;
  a {
    text-decoration: none;
  }
  .MuiButtonBase-root.MuiButton-root {
    border: 1px solid #1283ed;
    border-radius: 5px;
    letter-spacing: 0.6px;
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
  }
`;
export const PriceButton = styled(Button)``;
