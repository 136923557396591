import styled from "styled-components";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";

export const LandingBox = styled(Box)`
	width: 100%;
	overflow: hidden;
`;
// export const Line = styled(Box)`
// 	text-align: start;
// 	width: 83%;
// 	margin: 2rem auto 1rem;

// 	@media only screen and (max-width: 1240px) and (min-width: 1024px) {
// 		width: 90%;
// 	}
// 	@media only screen and (max-width: 768px) {
// 		margin: 2rem auto 0;
// 		width: 95%;
// 	}
// `;

export const Main = styled(Box)`
  width: 85%;
  margin: 0 auto 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .advanced {
    img {
		height: 85% !important;
    }
  }
  @media only screen and (max-width: 1240px) and (min-width: 1024px) {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    margin: 1rem auto;
    width: 95%;
  }
  @media only screen and (max-width: 480px) {
  }
`;

export const MainRightChild = styled(Box)`
	width: 50%;
	text-align: start;
	font-family: "Open Sans", sans-serif;
	.MuiTypography-root.MuiTypography-body1 {
		color: #7f8da0;
		font-size: 1.4rem;
		font-weight: 400;
		font-family: "Open Sans", sans-serif;
		margin: 1rem 0;
	}
	.MuiTypography-root.MuiTypography-h4 {
		display: inline-block;
		font-size: 3.5rem;
		color: #263238;
		font-family: "Open Sans", sans-serif;
		font-weight: bold;
		margin: 0 5rem 0 0;
		.MuiTypography-root.MuiTypography-h4 {
			color: #0091ea;
		}
	}
	.MuiTypography-root.MuiTypography-body2 {
		font-size: 1rem;
		color: #7f8da0;
		word-spacing: 4px;
		letter-spacing: 0px;
		font-weight: normal;
		font-family: "Open Sans", sans-serif;
		margin-top: 2rem;
	}
	@media only screen and (max-width: 640px) {
		width: 100%;
		margin: 1rem 0;
	}
	@media only screen and (max-width: 768px) {
		.MuiTypography-root.MuiTypography-body1 {
			font-size: 1rem;
			margin: 0 0 0.5rem 0;
		}
		.MuiTypography-root.MuiTypography-h4 {
			font-size: 2.5rem;
			margin: 0;
		}
		.MuiTypography-root.MuiTypography-body2 {
			word-spacing: 0.7px;
			font-size: 0.8rem;
		}
	}
`;

export const MainLeftChild = styled(Box)`
	width: 50%;
	position: relative;
	@media only screen and (max-width: 640px) {
		width: 90%;
		margin: 1rem auto;
	}
	@media only screen and (max-width: 768px) {
		width: 50%;
		margin: 1rem 0;
	}
	.ellipse {
		width: 40%;
		position: absolute;
		top: 10%;
		left: -5%;
	}
	.left-image {
		width: 90%;

		object-fit: contain;
		z-index: 11;
	}
	.rectangle {
		width: 100%;
		position: absolute;
		top: -40%;
		right: -80%;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
		overflow: hidden;
		margin-top: 1rem;
		.ellipse {
			width: 40%;
			position: absolute;
			top: 7%;
			left: 20%;
		}
		.left-image {
			width: 90%;
			margin: 2rem auto;
			object-fit: contain;
			z-index: 11;
		}
	}
`;

export const MainButtonBox = styled(Box)`
	width: 70%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin-top: 2rem;
	.MuiButtonBase-root.MuiButton-root {
		width: 45%;
		font-size: 0.8rem;
		font-weight: 600;
		text-transform: none;
		padding: 0.7rem 0;
		margin: 0.4rem 0;
		img {
			width: 15%;
			margin-right: 0.3rem;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 90%;
		margin: 1rem auto;
		.MuiButtonBase-root.MuiButton-root:last-of-type {
			width: 60%;
		}
	}
`;

export const MainButton = styled(Button)`
	a {
		text-decoration: none;
		color: #ffffff;
	}
`;

export const MainImageBox = styled(Box)`
  position: relative;
  width: 100%;
  margin: 3rem 0;
  .MuiTypography-root.MuiTypography-h4 {
    font-size: 1.3rem;
    margin: 0;
    text-align: justify;
    color: #7f8da0;
    font-family: "Open Sans", sans-serif;
	margin-bottom: 3rem;
  }
  .img {
    width: 100%;
    z-index: 11;
  }
  .ellipse {
    width: 20%;
    position: absolute;
    top: 10%;
    right: -29%;
  }
  .rect {
    width: 80%;
    position: absolute;
    top: 15%;
    left: -60%;
  }
  .rec {
    position: absolute;
    width: 70%;
    top: 50%;
    right: -35%;
  }
  @media only screen and (max-width: 480px) {
    overflow: hidden;
  }
`;

export const DatabaseBox = styled(Box)`
	width: 100%;
	margin: 3rem 0 0 0;
	z-index: 1;
	.MuiTypography-root.MuiTypography-h4 {
		color: #23233c;
		font-family: "Open Sans", sans-serif;
		font-weight: bold;
		font-size: 2.7rem;
	}
`;
export const DatabaseBoxChild = styled(Box)`
	position: relative;
	width: 100%;
	margin: 2rem auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	div {
		width: 100px;
		min-height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem 0;
		margin: 0.7rem;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		img {
			min-width: 80%;
			max-width: 85%;
			max-height: 120px;
			margin: auto;
		}
		@media only screen and (max-width: 480px) {
			min-height: 100px;
			width: 27%;
			margin: 1.5rem 0.5rem;
			img {
				width: 90%;
				max-height: 80px;
			}
		}
	}

	.ellipse {
		position: absolute;
		top: 70%;
		right: -30%;
	}
`;
export const ImageBox = styled(Box)`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	z-index: 4;

	img {
		width: 20%;
		object-fit: contain;
	}
`;
export const Maincontainer = styled(Box)`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;
export const MainBox = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
export const MainBoxChild = styled(Box)`
	width: 50%;
	text-align: start;
	.MuiTypography-root.MuiTypography-h4 {
		display: inline-block;
		font-size: 3rem;
		color: #263238;
		font-weight: bold;
		margin: 1rem 5rem 3rem 0;
		.MuiTypography-root.MuiTypography-h4 {
			color: #0091ea;
		}
	}
`;
export const MaincontainerChildA = styled(Box)`
	width: 45%;
	img {
		object-fit: contain;
		width: 90%;
	}
`;
export const MaincontainerChildB = styled(Box)`
	width: 50%;
	text-align: start;
`;

export const TypographyContainer = styled(Box)`
	width: 85%;
	.MuiTypography-root.MuiTypography-body1 {
		color: #9e9e9e;
		font-size: 1.08rem;
		margin: 0;
		&:last-of-type {
			margin: 1rem 0;
		}
	}
`;
