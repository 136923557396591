import {
	SectionBody,
	SectionBodyChildA,
	SectionBodyChildB,
	SectionBox,
	SectionHead,
	SectionHeadChild,
	TypographyContainer,
} from "./Backend.style";

// import line from "../../images/Line 4.png";

import rec from "../../images/Rectangle 117.png";
import Typography from "@mui/material/Typography";
export const Backend = (props) => {
	return (
		<SectionBox>
			<SectionHead>
				<SectionHeadChild>
					{/* <img src={line} alt="divider" /> */}
					<Typography variant="h4">
						{props.heading1}{" "}
						<Typography variant="h4">{props.heading2}</Typography>
					</Typography>
				</SectionHeadChild>
			</SectionHead>
			<SectionBody>
				<SectionBodyChildA>
					<img src={props.image} alt="child" className="img" />
				</SectionBodyChildA>
				<SectionBodyChildB>
					<img src={rec} alt="rec" className="rec" />
					<TypographyContainer>
						<Typography variant="body1">{props.text1}</Typography>
						<Typography variant="body1">{props.text2}</Typography>
						<Typography variant="body1">{props.text3}</Typography>
					</TypographyContainer>
				</SectionBodyChildB>
			</SectionBody>
		</SectionBox>
	);
};
