import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
export const SigninBox = styled(Box)`
	width: 100%;
`;

export const SigninChild = styled(Box)`
	width: 70%;
	margin: 7rem auto 15rem;
	text-align: center;
	position: relative;
	overflow: hidden;
	.ellipse {
		position: absolute;
		top: 10%;
		left: 45%;
		width: 35%;
	}
	.rectangle {
		position: absolute;
		top: -55%;
		right: -150%;
		width: 100%;
	}
	.MuiTypography-root.MuiTypography-h4 {
		font-size: 2.5rem;
		color: #263238;
		font-weight: bold;
		margin-top: 3rem;
	}
	.MuiPaper-root {
		border-radius: 10%;
	}
	@media only screen and (max-width: 768px) {
		width: 80%;
		margin: 2rem auto 10rem;
	}
	@media only screen and (max-width: 480px) {
		width: 95%;
		margin: 2rem auto 10rem;
	}
`;

export const TextBox = styled(Box)`
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 1;
	.MuiTypography-root.MuiTypography-h4 {
		font-size: 2.5rem;
		color: #263238;
		font-weight: bold;
		margin-top: 3rem;
	}
`;
export const FormBox = styled(Paper)`
	width: 50%;
	margin: 1rem auto;
	position: relative;
	z-index: 1;
	padding: 2rem;
	text-align: start;
	.MuiTypography-root.MuiTypography-body1 {
		font-size: 1rem;
		color: #263238;
		font-weight: 500;
		margin-top: 1rem;
	}
	.MuiFormControl-root.MuiTextField-root {
		width: 100%;
		margin: 1rem 0 2rem;
		height: 1.5rem;
		.MuiInputBase-root.MuiFilledInput-root {
			width: 100%;

			&:before {
				border-bottom: none;
			}
		}
	}
	.MuiButtonBase-root.MuiButton-root {
		width: 100%;
		margin-top: 1rem;
		text-transform: none;
	}

	.show {
		cursor: pointer !important;
	}
	.hide {
		cursor: not-allowed !important;
		background-color: #1976d2 !important;
	}
	@media only screen and (min-width: 1024px) and (max-width: 1240px) {
		width: 70%;
	}
	@media only screen and (max-width: 768px) {
		width: 70%;
	}
`;

export const RememberMe = styled(Box)`
	width: 100%;
	margin-top: 1rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	label {
		color: #263238;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	}
`;
export const ActionButton = styled(Button)`
div {
	width: 2rem !important;
	height: 1.8rem !important;
}
`;

export const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 40%;
	left: 50%;
`;
