import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GlobalStyle } from "./App.style";
import { Why } from "./pages/why/Why";
import { Indexpage } from "./pages/index/Indexpage";
// import { Feature } from "./pages/feature/Feature";
import "./App.css";
import { Pricing } from "./pages/pricing/Pricing";
import { About } from "./pages/about/About";
import { Documentation } from "./pages/documentation/Documentation";
import { Blog } from "./pages/blog/Blog";
import { Resources } from "./pages/resources/Resources";
import { Signin } from "./pages/signin/Signin";
import { Signup } from "./pages/signup/Signup";
import { EmailVerified } from "./pages/verifySuccess/EmailVerified";
import Careers from "./pages/careers/Careers";
function App() {
	return (
		<div className="App">
			<GlobalStyle />
			<Router>
				<Routes>
					<Route exact path="/" element={<Indexpage />} />
					<Route path="/why" element={<Why />} />
					{/* <Route path="/features" element={<Feature />} /> */}
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/about" element={<About />} />
					<Route path="/feature-brochure" element={<Documentation />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/resources" element={<Resources />} />
					<Route path="/signin" element={<Signin />} />
					<Route path="/signup" element={<Signup />} />
					
					<Route
						path="/email-Verification/success"
						element={<EmailVerified />}
					/>
					<Route path="/careers" element={<Careers />} />
					<Route path="*" element={<Indexpage />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
