import {
	SectionBody,
	SectionBodyChildA,
	SectionBodyChildB,
	SectionBox,
	SectionHead,
	SectionHeadChild,
	TypographyContainer,
} from "./Different.style";
// import line from "../../images/Line 4.png";
// import ellipse from "../../images/Ellipse 66.png";
import compass from "../../images/icons8-adventures-144.png";
import android from "../../images/icons8-android-os-240.png";
import apple from "../../images/icons8-apple-logo-240.png";
import chrome from "../../images/icons8-chrome-240.png";
import linux from "../../images/icons8-linux-240.png";
import opera from "../../images/icons8-opera-240.png";
import windows from "../../images/icons8-windows-10-240(1).png";
import Typography from "@mui/material/Typography";
export const Different = (props) => {
	const logoArray = [chrome, opera, compass, windows, apple, linux, android];
	return (
		<SectionBox>
			<SectionHead>
				<SectionHeadChild>
					{/* <img src={line} alt="divider" /> */}
					<Typography variant="h4">
						{props.heading1}{" "}
						<Typography variant="h4">{props.heading2}</Typography>
					</Typography>
				</SectionHeadChild>
			</SectionHead>
			<SectionBody>
				<SectionBodyChildA>
					<TypographyContainer>
						<Typography variant="body1">{props.text1}</Typography>
						<Typography variant="body1">{props.text2}</Typography>
						<Typography variant="body1">{props.text3}</Typography>
						<Typography variant="body1">{props.text4}</Typography>
					</TypographyContainer>
				</SectionBodyChildA>
				<SectionBodyChildB>
					<div>
						{logoArray.map((logo, index) => (
							<img key={index} src={logo} alt="logo" />
						))}
					</div>
				</SectionBodyChildB>
				{/* <img src={ellipse} alt="ellipse" className="ellipse" /> */}
			</SectionBody>
		</SectionBox>
	);
};
