import {
	Copyrightbox,
	FooterBox,
	FooterChild,
	FooterContainer,
	Footerhead,
	HeadBox,
	HeadchildA,
	HeadchildB,
	TextBox,
} from "./Footer.style";
// import { Link } from "react-router-dom";
import logo from "../../images/flexiblebase-15.png";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Typography } from "@mui/material";
import { RiFacebookFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
export const Footer = () => {
	return (
		<FooterBox className="footer">
			<FooterContainer>
				<FooterChild>
					<Footerhead>
						<HeadBox>
							<HeadchildA>
								<img src={logo} alt="logo" />
								<Typography variant="h4">Flexibase</Typography>
							</HeadchildA>
							{/* <HeadchildB>
								<a href="https://aedin.com/company/Flexiblebase">
									<FaLinkedinIn />
								</a>
								<a href="https://facebook.com/Flexiblebase">
									<RiFacebookFill />
								</a>
								<a href="https://twitter.com/Flexiblebase">
									<AiOutlineTwitter />
								</a>
							</HeadchildB> */}
							{/* <TextBox>
								<Typography variant="body1">USA</Typography>
								<Typography variant="body2">info@flexiblebase.com</Typography>
							</TextBox> */}
						</HeadBox>

            <HeadBox>
							<TextBox>
								{/* <Typography variant="body1">USA</Typography> */}
								<Typography variant="body2">info@flexiblebase.com</Typography>
							</TextBox>

              <Copyrightbox>
                <CopyrightIcon />
                <Typography variant="body1"> { new Date().getFullYear() } Flexiblebase</Typography>
              </Copyrightbox>
            </HeadBox>

						{/* <HeadBox>
							<HeadchildA>
								<Typography variant="h6">Services</Typography>
							</HeadchildA>
							<TextBox>
								<Link to="/">
									<Typography variant="body1">Buy a domain</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">Sell your domain</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">
										API partnership program
									</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">Brokerage</Typography>
								</Link>
							</TextBox>
						</HeadBox>
						<HeadBox>
							<HeadchildA>
								<Typography variant="h6">Learn</Typography>
							</HeadchildA>
							<TextBox>
								<Link to="/">
									<Typography variant="body1">News</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">Trust & Security</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">Help Center</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">Transaction Explorer</Typography>
								</Link>
							</TextBox>
						</HeadBox>
						<HeadBox>
							<HeadchildA>
								<Typography variant="h6">Company</Typography>
							</HeadchildA>
							<TextBox>
								<Link to="/careers">
									<Typography variant="body1">Career</Typography>
								</Link>
								<Link to="/about">
									<Typography variant="body1">Our story</Typography>
								</Link>
								<Link to="/">
									<Typography variant="body1">Contact us</Typography>
								</Link>
							</TextBox>
						</HeadBox> */}
					</Footerhead>

					{/* <Footerbody>
						{/* <Copyrightbox>
							<CopyrightIcon />
							<Typography variant="body1"> 2021 Flexiblebase</Typography>
						</Copyrightbox> 
					</Footerbody> */}
				</FooterChild>
			</FooterContainer>
		</FooterBox>
	);
};
