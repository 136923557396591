import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import { Box, Button, Toolbar, Typography } from "@mui/material";

export const MenuBox = styled(Box)`
	width: 90%;
	height: 20vh;
	margin: 0 auto;
	padding: 0;
	top: 0;
	z-index: 5;
	box-shadow: none;

	.MuiPaper-root.MuiAppBar-root {
		background-color: white !important;
		box-shadow: 0px 5px 10px -10px #111;

		padding: 1.5rem 6rem 0;
		position: fixed;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	@media only screen and (max-width: 1240px) and (min-width:900px){
		.MuiPaper-root.MuiAppBar-root {
			padding: 1.5rem 1rem 0.5rem !important;
		}
	}
	@media only screen and (min-width: 769px) {
		.MuiPaper-root.MuiAppBar-root {
			padding: 1.5rem 2.5rem 0.5rem;
		}
		
	}
	@media only screen and (max-width: 768px) {
		width: 95%;
		.MuiPaper-root.MuiAppBar-root {
			padding: 1.5rem 2.5rem 0.5rem;
		}
	}
	@media only screen and (max-width: 480px) {
		height: 15vh;
		.MuiPaper-root.MuiAppBar-root {
			padding: 1.5rem 0 0;
		}
	}
`;
export const MenuBar = styled(AppBar)`
	width: 100%;
	color: #000000;
`;

export const MenuToolBar = styled(Toolbar)`
	display: flex;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	justify-content: space-between;
	.MuiTypography-root {
		color: #000000;
		@media only screen and(max-width: 480px) {
			font-size: 2.5rem;
		}
	}
	.MuiButtonBase-root.MuiButton-root {
		text-transform: none;
		color: #9e9e9e;
		font-weight: 600;
	}
	@media only screen and (max-width: 768px) {
		width: 100%;
		padding: 0;
		.menu-div {
			width: unset !important;
		}
	}
	@media only screen and (min-width: 769px) {
		width: 100%;
		.menu-div {
			width: unset !important;
		}
	}
	@media only screen and (min-width: 480px) {
		padding: 0;
		padding-left: 0.8rem;
		.menu-div {
			width: 15% !important;
		}
	}
`;
export const MenuLogoBox = styled(Box)`
	width: 20%;
	display: flex;
	justify-content: flex-start;

	a {
		text-decoration: none;
		justify-content: space-around;
		display: flex;
		align-items: center;
	}
	img {
		height: 3.5rem;
	}
	.MuiTypography-root.MuiTypography-h4 {
		font-weight: 600;
		color: #263238;
		font-size: 1.8rem;
	}
	@media only screen and (max-width: 1240px) and (min-width:900px){
		width: 15%;
		img {
			width: 29%;
		}
		.MuiTypography-root.MuiTypography-h4 {
			font-weight: 600;
			color: #263238;
			font-size: 1.6rem;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 60%;
		justify-content: start;
	}
	@media only screen and (max-width: 380px){
		width: 60%;
		
		img {
			width: 25%;
		}
		.MuiTypography-root.MuiTypography-h4{
			font-size: 1.4rem;
		}
	}
`;
export const MenuLogo = styled(Typography)`
	color: #ffffff;
`;

export const MenuText = styled(Typography)`
	font-size: 0.9rem;
	line-height: 18.96px;
`;
export const ButtonBoxA = styled(Box)`
	width: 50%;
	display: flex;
	margin-right: 2rem;
	align-items: center;
	justify-content: space-between;
	a {
		color: white;
		text-decoration: none;
		.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary {
			color: #000000;
			border: 1px solid #ffffff;
			&:hover {
				border: none;
				outline: none;
			}
		}

		.MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
			letter-spacing: 0px;
			color: #7f8da0;
			font-size: 1rem;
			font-weight: 400;
			MuiSvgIcon-root {
				color: #000000;
			}
			&:hover {
				border: 1px solid #ffffff;
			}
		}
	}
	.MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
		letter-spacing: 0px;
		color: #7f8da0;
		font-size: 1rem;
		font-weight: 400;
		MuiSvgIcon-root {
			color: #000000;
		}
		&:hover {
			border: 1px solid #ffffff;
		}
	}
	@media only screen and (max-width: 1240px) and (min-width:900px){
		width: 50%;
		margin-right: 0;
		margin-left: 2.5rem;
	}
`;
export const ButtonBoxB = styled(Box)`
	width: 20%;
	display: flex;
	justify-content: end;
	padding-right: 1rem;
	a {
		text-decoration: none;
		.MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
			font-size: 1rem;
			&:hover {
				border: 1px solid #ffffff;
			}
		}
		.sign {
			color: #23233c !important;
			font-weight: bold !important;
		}
		.free {
			color: #1283ed !important;
			font-weight: bold !important;
		}
	}
	@media only screen and (max-width: 1240px) and (min-width:900px){
		width: 22%;
		margin: 0 auto;
	}
`;
export const NavButton = styled(Button)`
	outline: none;
	border: none;
	border-radius: 4px;
	.resources {
		color: black;
	}
`;
