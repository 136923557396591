import { Container, ContainerChild } from "./verified.style";
import { Button, Typography } from "@mui/material";
import check from "../../images/blue-check.jpg";
import { Link } from "react-router-dom";

export const EmailVerified = () => {
	return (
		<Container>
			<ContainerChild>
				<Typography variant="h2">Email successfully verified!</Typography>
				<img src={check} alt="checkmark" />
				<Link to="/login" style={{ textDecoration: "none" }}>
					<Button variant="contained">Login</Button>
				</Link>
			</ContainerChild>
		</Container>
	);
};
