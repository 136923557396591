import Navbar from "../navbar/Navbar";
// import line from "../../images/Line 4.png";
// import ellipse from "../../images/Ellipse 66.png";
import rectangle from "../../images/Rectangle 116.png";
import rect from "../../images/Rectangle 335.png";
import rec from "../../images/Rectangle 117.png";
import group from "../../images/Flexiblebase Toolbox.png";
import play from "../../images/play-button-svgrepo-com.png";
import data from "../../images/01 - Showcase.png";
import mysql from "../../images/MySQL-Logo.wine.png";
import mariadb from "../../images/mariadb-logo-vert_blue-transparent-1.png";
import google from "../../images/1 zuTTkJe54Wv907enmQt46g.png";
import cassandra from "../../images/2560px-Cassandra_logo.svg.png";
import amazon from "../../images/60232d51a86b90a0ffb97428_amazon-aurora.png";
import rds from "../../images/Amazon-RDS-1.png";
import heroku from "../../images/Heroku_logo.svg.png";
import hp from "../../images/hp.png";
import microsoft from "../../images/images-1.png";
import oracle from "../../images/Rectangle -1.png";
import microsoftsql from "../../images/download.jpg";
import post from "../../images/postres.png";
import memsql from "../../images/MemSQL_horizontal_lockup_blue_logo.png";
import db1 from "../../images/03 - CRUD Operations.png";
import gui from "../../images/02 - SQL GUI.png";
import secure from "../../images/shield.png";
import htmlGenerator from "../../images/05 - Automatic HTML Form Generator.png";
// import dbtable from "../../images/Screenshot -2.png";
import api from "../../images/api_code.png";
import visual from "../../images/04 - Data Visualization.png";
import viewer from "../../images/06 - Document and Image Viewer.png";
import { Link } from "react-router-dom";
import {
  LandingBox,
  // Line,
  Main,
  MainButton,
  MainButtonBox,
  MainLeftChild,
  MainRightChild,
  MainImageBox,
  DatabaseBox,
  DatabaseBoxChild,
  ImageBox,
} from "./Landing.style";
import Typography from "@mui/material/Typography";
import { Normal } from "../main-normal/Normal";
import { Reverse } from "../main-reverse/Reverse";
import { Different } from "../main-different/Different";
import { Pricing } from "../pricing/Pricing";
import { Backend } from "../main-backend/Backend";

// import { Feedback } from "../demo-feedback/Feedback";
// import { Trust } from "../trust/Trust";
export const Landing = () => {
  const imageArray = [
    microsoftsql,
    mysql,
    post,
    mariadb,
    //oracle,
    rds,
    microsoft,
    google,
    amazon,
    heroku,
    memsql
  ];
  return (
    <LandingBox>
      <Navbar />
      {/* <Line>
				<img src={line} alt="line" />
			</Line> */}
      <Main>
        <MainRightChild>
          {/* <Typography variant="body1">
            Compatible with all database query languages
          </Typography> */}
          <Typography variant="h4">
            The <Typography variant="h4">Database</Typography>
            <br /> Toolbox
          </Typography>
          <Typography variant="body2">
            A secure, interactive, and collaborative platform for
            <br /> managing databases using any device
          </Typography>
          <MainButtonBox>
            <MainButton
              variant="contained"
              style={{ backgroundColor: "#1283EDA6" }}
            >
              Live Demo
            </MainButton>
            <MainButton
              variant="contained"
              style={{ backgroundColor: "#1283ED" }}
            >
              <Link to="/signup">Sign Up</Link>
            </MainButton>
            <MainButton variant="outlined">
              <img src={play} alt="play" />
              Watch Illustration
            </MainButton>
          </MainButtonBox>
        </MainRightChild>
        <MainLeftChild>
          {/* <img src={ellipse} alt="ellipse" className="ellipse" /> */}
          <img src={group} alt="group" className="left-image" />
          <img src={rectangle} alt="rectangle" className="rectangle" />
        </MainLeftChild>
        <MainImageBox>
          <Typography variant="h4">Flexiblebase is a platform (web, mobile & api) for schema design, table creation, data management (CRUD operations), SQL Query GUI, team collaboration, data visualization, and data sharing. It integrates backend api with any SQL database and has tools to generate frontend UI using leading JavaScript frameworks.</Typography>
          <ImageBox>
            <img src={data} alt="database" className="img" />{" "}
          </ImageBox>
          <img src={rect} alt="rect" className="rect" />
          <img src={rec} alt="rec" className="rec" />
          {/* <img src={ellipse} alt="ellipse" className="ellipse" /> */}
        </MainImageBox>
        <DatabaseBox>
          <Typography variant="h4">Connect your favorite database</Typography>
          <DatabaseBoxChild>
            {imageArray.map((image, index) => (
              <div>
                <img key={index} src={image} alt="database example" />
              </div>
            ))}

            {/* <img src={ellipse} alt="ellipse" className="ellipse" /> */}
          </DatabaseBoxChild>
        </DatabaseBox>
        <Reverse
          heading1="Write SQL Query"
          heading2="Like a Pro"
          image={gui}
          text1="Flexiblebase helps you to write and run SQL query like a pro! It comes with the powerful syntax highlighting, query composer, auto completion of key words, line number, DDL & DML code snippets, ability to save your query for later use and lots"
        />
        <Normal
          heading1="CRUD"
          heading2="Operations"
          image={db1}
          text1="Flexiblebase operates like a generic app built on top of your
							databse and this gives you access to perform CRDU (Create, Read,
							Operate, Delete) operations, you can choose between inline editing
							and form editing."
        />
        <Reverse
          heading1="Data"
          heading2="Visualization"
          image={visual}
          text1="Visualize your data analytics
real-time on the
dashboard. With Flexiblebase
Data Visualization
AI, you do not need to do
anything to see your business
performance visualization
across board."
        />
        <Normal
          heading1="Automatic"
          heading2="HTML Generator"
          image={htmlGenerator}
          text1="Accelerate your frontend development by 70% with Flexiblebase form, table and code generator  using the leading JavaScript framework such as Vuejs, React and Angular."
        />
        	<Reverse
						heading1="Document"
						heading2="Viewer"
						image={viewer}
						text1="Visualizing your images stored in binary, image or blob format from your database
make you feel you are using a customised app already."
					/>
        <Backend
          heading1="Backend"
          heading2="APIs"
          image={api}
          text1="Flexiblebase provides a real-time backend API services for developers to connect their apps to their remote sql database."
          text2="All you need to do is sign up on flexiblebase, connect to your sql databse, include your api access token in your web or mobile app and start communicating with your database from your frontend app"
          text3="No need to code backend in C#, PHP, Java and Python etc again."
        />
        <Different
          heading1="Cross"
          heading2="Platform"
          text1="Flexible is cross platform modern app"
          text2="Client: Web Browser such as chrome, Firefox, safari, edge, opera etc. Mobile Phone Browser, Android App, iOS App"
          text3="Server: Windows, MacOS, and Linux"
          text4="Usage: On premise (including support for localhost) and in the cloud"
        />

        <Normal
        className="advanced"
          heading1="Advanced"
          heading2="Security"
          image={secure}
          text1="Account Login: Flexiblebase enable 2-factor authentication by default. It also notifies you whenever there is a login to your account"
          text2="Connection: Flexiblebase uses SSL/TLS AES 256 encryption for all communication between your browser, our server, and your database server. Your connection details are stored fully encrypted in your local computer. No connection database credentials are stored on our server."
          text3="Data security: Flexiblebase accesses your data in real-time and only retreives the rows necessary to support your request at a given time. All data transfer from our server to your browser are erncrypted with SSL/TLS AES 256 encryption, we do not store any of your data on our server"
        />
        <Pricing />
      </Main>
      {/* <Trust />
      <Feedback /> */}
    </LandingBox>
  );
};
