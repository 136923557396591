import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { Button } from "@mui/material";
import { CareersDiv, Container, ContainerChild } from "./career.style";
import Navbar from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";
const Careers = () => {
	return (
		<Container>
			<Navbar />
			<ContainerChild>
				<Typography variant="h4">Careers</Typography>
				<CareersDiv>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
					<Paper>
						<Typography variant="h6">Title</Typography>
						<Typography variant="body1">
							Lorem ipsum dolor sit amet consectetur, adipisicing elit.
							Voluptatibus, ratione nemo. Libero reiciendis numquam laboriosam
							commodi similique in cum nihil omnis id, accusantium quibusdam
							expedita. Neque aliquid assumenda exercitationem reiciendis.
						</Typography>
						<div>
							<Button variant="text">Read More</Button>
						</div>
					</Paper>
				</CareersDiv>
			</ContainerChild>
			<Footer />
		</Container>
	);
};

export default Careers;
