import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const WhyBox = styled(Box)`
	width: 100%;
	overflow: hidden;
`;

export const Main = styled(Box)`
	width: 85%;
	margin: 5rem auto 2rem;
	& > div:not(:first-of-type) {
		margin: 4.5rem 0 !important;
	}
	@media only screen and (max-width: 480px) {
		width: 95%;
		margin: 2rem auto;
	}
`;

export const Mainchild = styled(Box)`
	width: 100%;
	text-align: center;
	margin-bottom: 5rem;
	position: relative;
	.ellipse {
		position: absolute;
		top: 9%;
		left: 50%;
		width: 20%;
	}
	.rectangle {
		position: absolute;
		top: -75%;
		right: -80%;
	}

	@media only screen and (min-width: 1024px) and (max-width: 1240px) {
		width: 90%;
		.ellipse {
			left: 30%;
			width: 40%;
		}
		.rectangle {
			position: absolute;
			top: -10%;
			right: -90%;
			width: 90%;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 90%;
		.ellipse {
			left: 30%;
			width: 50%;
		}
		.rectangle {
			position: absolute;
			top: -10%;
			right: -80%;
			width: 90%;
		}
	}
	@media only screen and (max-width: 480px) {
		margin: 2rem auto;
		.ellipse {
			left: 30%;
			width: 50%;
		}
		.rectangle {
			position: absolute;
			top: 0%;
			right: -80%;
			width: 90%;
		}
	}
`;
export const TextBox = styled(Box)`
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 2;
	.MuiTypography-root.MuiTypography-body1 {
		letter-spacing: 0px;
		color: #7f8da0;
		font-size: 1.2rem;
		font-family: "Open Sans", sans-serif;
		margin: 1rem 0;
	}
	.MuiTypography-root.MuiTypography-h4 {
		display: inline-block;
		font-size: 3rem;
		color: #23233c;
		font-weight: bold;
		margin-bottom: 1rem;
		.MuiTypography-root.MuiTypography-h4 {
			color: #0091ea;
		}
	}
	.MuiTypography-root.MuiTypography-body2 {
		font-size: 1rem;
		letter-spacing: 0px;
		color: #7f8da0;
		font-family: "Open Sans", sans-serif;
	}
	@media only screen and (max-width: 640px) {
		width: 100%;
		margin: 1rem 0;
	}
	@media only screen and (max-width: 768px) {
		.MuiTypography-root.MuiTypography-body1 {
			font-size: 1rem;
			margin: 0 0 0.5rem 0;
		}
		.MuiTypography-root.MuiTypography-h4 {
			font-size: 2.5rem;
			margin: 0;
		}
		.MuiTypography-root.MuiTypography-body2 {
			word-spacing: 0.7px;
			font-size: 0.8rem;
		}
	}
	@media only screen and (max-width: 768px) {
		.MuiTypography-root.MuiTypography-body1 {
			font-size: 1rem;
			margin: 0 0 0.5rem 0;
		}
		.MuiTypography-root.MuiTypography-h4 {
			font-size: 2rem;
			margin: 0;
		}
		.MuiTypography-root.MuiTypography-body2 {
			word-spacing: 0.7px;
			font-size: 0.8rem;
		}
	}
	div {
		width: 70%;
		margin: 0 auto;
		text-align: center;
		@media only screen and (max-width: 480px) {
			width: 100%;
		}
	}
`;
export const TextBox2 = styled(Box)`
	width: 70%;
	margin: 1rem auto 0;
	text-align: center;
	.MuiTypography-root.MuiTypography-body2 {
		font-size: 1rem;
		letter-spacing: 0px;
		color: #7f8da0;
		font-family: "Open Sans", sans-serif;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
export const MainButtonBox = styled(Box)`
	width: 35%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin: 2rem auto 0;
	.MuiButtonBase-root.MuiButton-root {
		width: 40%;
		font-size: 0.8rem;
		font-weight: 600;
		text-transform: none;
		padding: 0.7rem 0;
		margin: 0.4rem 0;
		img {
			width: 15%;
			margin-right: 0.3rem;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 80%;
		.MuiButtonBase-root.MuiButton-root:last-of-type {
			width: 65%;
		}
	}
`;

export const MainButton = styled(Button)`
	a {
		text-decoration: none;
		color: #ffffff;
	}
`;
