import styled from "styled-components";
import Box from "@mui/material/Box";

export const Resourcesbox = styled(Box)`
	width: 100%;
	overflow: hidden;
`;

export const Main = styled(Box)`
	width: 85%;
	margin: 3rem auto;
	text-align: center;
	position: relative;
	z-index: 2;
	.ellipse {
		position: absolute;
		top: 10%;
		left: 40%;
		width: 25%;
	}
	.rectangle {
		position: absolute;
		top: -85%;
		right: -80%;
	}
	.MuiTypography-root.MuiTypography-body1 {
		letter-spacing: 0px;
		color: #7f8da0;
		font-size: 1.2rem;
		font-family: "Open Sans", sans-serif;
		margin: 1rem 0;
	}
	.MuiTypography-root.MuiTypography-body2 {
		margin: 5rem 0;
		letter-spacing: 0px;
		color: #7f8da0;
		font-size: 1.2rem;
		font-family: "Open Sans", sans-serif;
	}
	@media only screen and (min-width: 1024px) and (max-width: 1240px) {
		width: 90%;
		.ellipse {
			left: 35%;
			width: 35%;
		}
		.rectangle {
			position: absolute;
			top: -30%;
			right: -80%;
			width: 90%;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 90%;
		.ellipse {
			left: 30%;
			width: 40%;
		}
		.rectangle {
			position: absolute;
			top: -40%;
			right: -75%;
			width: 90%;
		}
	}
	@media only screen and (max-width: 480px) {
		width: 95%;
		.ellipse {
			left: 25%;
			width: 50%;
		}
		.rectangle {
			position: absolute;
			top: -25%;
			right: -70%;
			width: 90%;
		}
	}
`;

export const Mainchild = styled(Box)`
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 2;
	.MuiTypography-root.MuiTypography-body1 {
		margin: 5rem 0;
		font-size: 1.2rem;
		color: #9e9e9e;
	}
	.MuiTypography-root.MuiTypography-h4 {
		font-size: 2.5rem;
		color: #263238;
		font-weight: bold;
	}
`;
