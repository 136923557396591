import styled from "styled-components";
import { Box } from "@mui/material";

export const Container = styled(Box)`
	width: 100%;
`;

export const ContainerChild = styled(Box)`
	width: 80%;
	margin: 5rem auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.MuiTypography-root.MuiTypography-h2 {
		color: #263238;
		@media only screen and (max-width: 480px) {
			font-size: 3rem;
		}
	}
	img {
		margin-top: 1rem;
	}
	.MuiButton-root.MuiButton-contained {
		margin: 2rem 0 0;
		padding: 0.5rem 1.7rem;
	}
`;
