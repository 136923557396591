// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// import { A11y, Autoplay, Navigation } from "swiper";
import {
	FeedbackBox,
} from "./Feedback.style";
// import { Typography } from "@mui/material";

// import bg from "../../images/Group 1711.png";
// import path from "../../images/Path 3818.png";
// import left from "../../images/arrow-long-1.png";
// import right from "../../images/arrow-long.png";
// import image1 from "../../images/asset-1.png";
// import image2 from "../../images/202020.png";
// import image3 from "../../images/coaches-1.png";
// import Avatar from "@mui/material/Avatar";
// import GradeIcon from "@mui/icons-material/Grade";
// import { tns } from "./node_modules/tiny-slider/src/tiny-slider";



export const Feedback = () => {
	// const breakpoints = {
	// 	320: {
	// 		slidesPerView: 1,
	// 		spaceBetween: 0,
	// 	},
	// 	// when window width is >= 480px
	// 	580: {
	// 		slidesPerView: 2,
	// 		spaceBetween: 5,
	// 	},
	// 	// when window width is >= 640px
	// 	870: {
	// 		slidesPerView: 3,
	// 		spaceBetween: 20,
	// 	},
	// 	980: {
	// 		slidesPerView: 3,
	// 		spaceBetween: 10,
	// 	},
	// 	1024: {
	// 		slidesPerView: 3,
	// 		spaceBetween: 40,
	// 	},
	// };
	return (

		<FeedbackBox mode="carousel">
      {/* Height set to 0 */}
    </FeedbackBox>

		// <FeedbackBox mode="carousel">
		// 	<FeedbackHead>
		// 		{/* <img src={line} alt="line" /> */}
		// 		<HeadChild>
		// 			<Typography variant="h4">What users says about us?</Typography>
		// 			{/* <ArrowBox>
		// 				<img src={left} alt="arrow left" />
		// 				<img src={right} alt="arrow right" />
		// 			</ArrowBox> */}
		// 			{/* <ButtonGroup currentSlide={props?.currentSlide} /> */}
		// 		</HeadChild>
		// 		<Typography variant="body2">
		// 			Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
		// 			odit velit maiores enim
		// 			<br /> ipsum veritatis animi doloribus doloremque,
		// 		</Typography>
		// 	</FeedbackHead>
		// 	<FeedbackBody>
		// 		<img src={bg} alt="background" className="back" />
		// 		{/* <img src={path} alt="feedback background" className="path" /> */}
		// 		<SwiperBox>
		// 			<Swiper
		// 			modules={[A11y, Autoplay, Navigation]}
		// 			spaceBetween={25}
		// 			slidesPerView={3}
		// 			navigation={true}
		// 			autoplay={true}
		// 			animation={true}
		// 			effect="flip"
		// 			loop={true}
		// 			centeredSlides={true}
		// 			breakpoints={breakpoints}
		// 			>
		// 					<SwiperSlide>
		// 						<FeedbackCard>
		// 							<Avatar src={image1} alt="" />
		// 							<RatingsCard>
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 							</RatingsCard>
		// 							<Typography variant="body1">
		// 								Lorem ipsum dolor sit amet consectetur adipisicing elit.
		// 								Repellat, illo architecto distinctio, ad impedit laboriosam{" "}
		// 							</Typography>
		// 							<Typography variant="h6">Christopher Doe</Typography>
		// 							<Typography variant="body2">Orlando, Florida</Typography>
		// 						</FeedbackCard>
		// 					</SwiperSlide>
		// 					<SwiperSlide>
		// 						<FeedbackCard>
		// 							<Avatar src={image2} alt="" />
		// 							<RatingsCard>
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 							</RatingsCard>
		// 							<Typography variant="body1">
		// 								Lorem ipsum dolor sit amet consectetur adipisicing elit.
		// 								Repellat, illo architecto distinctio, ad impedit laboriosam{" "}
		// 							</Typography>
		// 							<Typography variant="h6">Sarah James</Typography>
		// 							<Typography variant="body2">Orlando, Florida</Typography>
		// 						</FeedbackCard>
		// 					</SwiperSlide>
		// 					<SwiperSlide>
		// 						<FeedbackCard>
		// 							<Avatar src={image3} alt="" />
		// 							<RatingsCard>
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 							</RatingsCard>
		// 							<Typography variant="body1">
		// 								Lorem ipsum dolor sit amet consectetur adipisicing elit.
		// 								Repellat, illo architecto distinctio, ad impedit laboriosam{" "}
		// 							</Typography>
		// 							<Typography variant="h6">Janee Smith</Typography>
		// 							<Typography variant="body2">Orlando, Florida</Typography>
		// 						</FeedbackCard>
		// 					</SwiperSlide>
		// 					<SwiperSlide>
		// 						<FeedbackCard>
		// 							<Avatar src={image1} alt="" />
		// 							<RatingsCard>
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 							</RatingsCard>
		// 							<Typography variant="body1">
		// 								Lorem ipsum dolor sit amet consectetur adipisicing elit.
		// 								Repellat, illo architecto distinctio, ad impedit laboriosam{" "}
		// 							</Typography>
		// 							<Typography variant="h6">Christopher Doe</Typography>
		// 							<Typography variant="body2">Orlando, Florida</Typography>
		// 						</FeedbackCard>
		// 					</SwiperSlide>
		// 					<SwiperSlide>
		// 						<FeedbackCard>
		// 							<Avatar src={image1} alt="" />
		// 							<RatingsCard>
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 								<GradeIcon />
		// 							</RatingsCard>
		// 							<Typography variant="body1">
		// 								Lorem ipsum dolor sit amet consectetur adipisicing elit.
		// 								Repellat, illo architecto distinctio, ad impedit laboriosam{" "}
		// 							</Typography>
		// 							<Typography variant="h6">Christopher Doe</Typography>
		// 							<Typography variant="body2">Orlando, Florida</Typography>
		// 						</FeedbackCard>
		// 					</SwiperSlide>
		// 			</Swiper>
		// 		</SwiperBox>
		// 	</FeedbackBody>
		// </FeedbackBox>
	);
};
