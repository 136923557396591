import { useEffect } from "react";
import { Blogbox, Main, Mainchild } from "./Blog.style";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "@mui/material";

import rectangle from "../../images/Rectangle 116.png";
import { Footer } from "../../components/footer/Footer";
export const Blog = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<Blogbox>
			<Navbar />
			<Main>
				<img src={rectangle} alt="rectangle" className="rectangle" />
				<Mainchild>
					<Typography variant="body1">
						Follow our company news and stories
					</Typography>
					<Typography variant="h4">Blog</Typography>
				</Mainchild>
				<Typography variant="body2">
					There is no content for this page yet
				</Typography>
			</Main>
			<Footer />
		</Blogbox>
	);
};
