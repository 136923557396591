import { useState, useEffect } from "react";
import {
  ActionButton,
  FormBox,
  RememberMe,
  SigninBox,
  SigninChild,
  TextBox,
  
} from "./Signin.style";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import rectangle from "../../images/Rectangle 116.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

export const Signin = () => {
  const [signinInfo, setsigninInfo] = useState({
    email: "",
    password: "",
    rememberMe: false
  });
  
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(true);

  const navigate = useNavigate();
  const handleChange = (e) => {
    setsigninInfo({ ...signinInfo, [e.target.name]: e.target.value });
  };
  const handleCheck = (e) => {
    setsigninInfo({...signinInfo, [e.target.name]: e.target.checked });
  };  
  useEffect(() => {
	console.log(signinInfo);
  }, [signinInfo, click]);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(signinInfo);
	setClick(false)
    setLoading(true);
    axios
      .post("https://server.flexiblebase.com/login", signinInfo)
      .then((response) => {
        console.log(response);
        setLoading(false);
		setClick(true)
    let credentials = response.data
    const now = new Date();
        const expirationDate = new Date(now.getTime() + credentials.expiresIn * 1000);
     console.log(expirationDate);
        localStorage.setItem("token", credentials.token);
        localStorage.setItem("userName", credentials.userName);
        localStorage.setItem("role", credentials.role);
        localStorage.setItem("rememberMe", credentials.rememberMe);
        localStorage.setItem("userAccount", credentials);
    
        if (signinInfo.rememberMe === true) {
     localStorage.setItem("expirationDate", credentials.expirationDate);
        };    
    navigate("app.flexiblebase.com")
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
		setClick(true);
        toast.error(error.response?.data.message);
      });
  };
  return (
    <SigninBox>
      <Navbar />
      <SigninChild>
        <img src={rectangle} alt="rectangle" className="rectangle" />
        <TextBox>
          <Typography variant="h4">Sign In</Typography>
        </TextBox>
        <FormBox>
          <form action="" onSubmit={handleSubmit}>
            <Typography variant="body1">Email</Typography>
            <TextField
              id="email"
              label="Enter your email address"
              variant="filled"
              onChange={handleChange}
              value={signinInfo.email}
              name="email"
            />
            <Typography variant="body1">Password</Typography>
            <TextField
              id="password"
              type="password"
              label="Enter your password"
              variant="filled"
              onChange={handleChange}
              value={signinInfo.password}
              name="password"
            />

            <RememberMe>
              <input
                type="checkbox"
                name="rememberMe"
                id="rememberMe"
                checked={signinInfo.rememberMe}
                onChange={handleCheck}
              />
              <label htmlFor="remember">Remember Me</label>
            </RememberMe>
            <ActionButton variant="contained" type="submit" className={click ? "show" : "hide"}>
              
			 {loading ? (
				  <ReactLoading type={"spinningBubbles"} color="#bbdefb"  />) : "Sign In"} 
				
            </ActionButton>
           
          </form>
        </FormBox>
      </SigninChild>
      <Footer />
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose="4000"
        hideProgressBar="false"
        closeOnClick
      ></ToastContainer>
    </SigninBox>
  );
};
