import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
 body {
     width: 100%;
     height: 100%;
     margin: 0;
     overflow-x: hidden;
     overflow-y: visible;
     ::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.MuiModal-root.MuiPopover-root.MuiMenu-root{
    .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper{
        border-radius: 5%;
       .MuiList-root.MuiMenu-list{
           .MuiButtonBase-root.MuiMenuItem-root{
               a {
                   color: #9e9e9e;
                   text-decoration: none;
                   .MuiButtonBase-root.MuiButton-root{
                       color: #9e9e9e;
                   }
               }
                .MuiButtonBase-root.MuiButton-root.MuiButton-textPrimary {
                     color: #9e9e9e;
                }
           }
       }
    }
}
 }
`;
