import { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "@mui/material";
import rectangle from "../../images/Rectangle 116.png";
import { Footer } from "../../components/footer/Footer";
import { Main, Mainchild, Resourcesbox } from "./Resources.style";
export const Resources = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<Resourcesbox>
			<Navbar />
			<Main>
				<img src={rectangle} alt="rectangle" className="rectangle" />
				<Mainchild>
					<Typography variant="body1">
						External SQL Links and Resources
					</Typography>
					<Typography variant="h4">SQL Resources</Typography>
				</Mainchild>
				<Typography variant="body2">
					There is no content for this page yet
				</Typography>
			</Main>
			<Footer />
		</Resourcesbox>
	);
};
