import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Paper } from "@mui/material";
// import left from "../../images/arrow-long-1.png";
// import right from "../../images/arrow-long.png";

export const FeedbackBox = styled(Box)`
	width: 100%;
	height: 0rem;
	position: relative;
	z-index: 10;
	margin-bottom: 4rem;
	margin-top: 3rem;
	background-image: url("../../images/Path 3818.png");
	@media only screen and (max-width: 480px) {
		margin-bottom: 2rem;
	}
`;

export const FeedbackHead = styled(Box)`
	width: 80%;
	margin: 2rem auto 4rem;
	text-align: start;
	position: absolute;
	top: -10%;
	left: 10%;
	z-index: 4;
	.MuiTypography-root.MuiTypography-body2 {
		font-size: 1rem;
		color: #9e9e9e;
	}
	@media only screen and (min-width: 481px) and (max-width: 758px) {
		top: -5%;
		left: 5%;
	}
	@media only screen and (max-width: 480px) {
		width: 95%;
		left: 4%;
		.MuiTypography-root.MuiTypography-body2 {
			font-size: 0.8rem;
		}
	}
`;

export const HeadChild = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	.MuiTypography-root.MuiTypography-h4 {
		font-size: 2rem;
		font-weight: bold;
		color: #263238;
		margin-bottom: 2rem;
	}
	@media only screen and (max-width: 480px) {
		.MuiTypography-root.MuiTypography-h4 {
			font-size: 1.5rem;
		}
	}
`;
export const ArrowBox = styled(Box)`
	width: 15%;
	display: flex;
	justify-content: space-between;

	img {
		width: 25%;
		height: 2rem;
	}
	@media only screen and (max-width: 480px) {
		display: none;
		width: 35%;
		img {
			width: 40%;
			display: none;
		}
	}
`;
export const ArrowItem = styled(Button)`
	width: 40%;
	img {
		width: 95%;
	}
`;
export const FeedbackBody = styled(Box)`
	width: 100%;
	height: 90%;
	position: relative;
	z-index: 0;
	margin: 4rem 0;
	background-image: url("../../images/Group 1711.png");
	display: flex;
	justify-content: flex-end;
	overflow: visible;
	.back {
		position: absolute;
		top: 45%;
		left: 8%;
		height: 20rem;
	}
	.path {
		position: absolute;
		right: 0;
		top: -15%;
		width: 40%;
		height: 45rem;
	}
`;
export const SwiperBox = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	.swiper {
		width: 89%;
		margin: 0;
		overflow: hidden;
		position: relative;
		.swiper-button-prev {
			width: 4%;
			border-radius: 50%;
			
			background-color: #23233c;
		
			right: 18%;
			top: 17%;
			left: unset;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			&:after {	
				color: #f4f5fa !important;
				font-size: 2rem;
			}
		}
		.swiper-button-next {
			width: 4%;
			border-radius: 50%;
			background-color: #f4f5fa;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			top: 17%;
			right: 10%;
		
			&:after {

				color: #23233c;
				font-size: 2rem;
			}
		}
		.swiper-wrapper {
			width: 100%;
			margin-top: 6rem;
			
			overflow: visible;
			.swiper-slide {
				.MuiPaper-root {
					text-align: start;
				}
			}
		}
		@media only screen and (min-width: 489px) and (max-width: 680px) {
			.swiper-button-prev {
				top: -10%;
				z-index: 5;
			}
			.swiper-button-next {
				top: -10%;
			}
		}
		@media only screen and (max-width: 768px) {
			width: 95%;
			margin: 2rem 0;
			.swiper {
				overflow: visible;
				.swiper-wrapper {
					.swiper-slide {
						width: 48%;
						margin: 0 1rem;
					}
				}
			}
			.swiper-button-prev {
				::after {
					font-size: 2rem;
				}
			}
			.swiper-button-next {
				::after {
					font-size: 2rem;
				}
			}
		}
		@media only screen and (max-width: 480px) {
			width: 100%;
			margin: 2rem 0;
			.swiper-wrapper {
				.swiper-slide {
					width: 90%;
					margin: 0;
				}
			}
			.swiper-button-prev {
				margin-top: 2rem;
				width: 10%;
				right: 15%;
				top: 15%;
				::after {
					font-size: 2rem;
				}
			}
			.swiper-button-next {
				margin-top: 2rem;
				width: 10%;
				right: 2%;

				top: 15%;
				::after {
					font-size: 2rem;
				}
			}
		}
	}
`;
export const FeedbackCard = styled(Paper)`
	width: 80%;
	padding: 2rem;
	margin: 5rem 2rem 2rem 0;
	overflow: visible;
	.MuiAvatar-root {
		width: 18%;
		height: 5.5rem;
		margin-top: -4rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.MuiTypography-root.MuiTypography-body1 {
		color: #9e9e9e;
	}
	.MuiTypography-root.MuiTypography-body2 {
		color: #9e9e9e;
	}
	.MuiTypography-root.MuiTypography-h6 {
	}
`;

export const RatingsCard = styled(Box)`
	width: 80%;
	margin: 2rem 0;
	display: flex;
	.MuiSvgIcon-root {
		width: 1.2rem;
		height: 1.2rem;
		color: yellow;
	}
`;
