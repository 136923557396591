import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {Link} from "react-router-dom";
import {
	ChildBody,
	ChildHead,
	ChildMain,
	DifText,
	Info,
	InfoBox,
	PriceBox,
	PriceButton,
	PriceButtonBox,
	PricingBody,
	PricingBodyChild,
	PricingBox,
	PricingHead,
} from "./Pricing.style";
export const Pricing = () => {
	return (
		<PricingBox>
			<PricingHead>
				<Typography variant="h4">Pricing</Typography>
				<Typography variant="body1">Choose a Suitable Plan</Typography>
			</PricingHead>
			<PricingBody>
				<PricingBodyChild>
					<ChildHead>
						<Typography variant="h5">Free</Typography>
						<Typography variant="body1">
							Ideal for hobbyist and students
						</Typography>
					</ChildHead>
					<ChildMain>
						<PriceBox>
							<Typography variant="h4">$0</Typography>
							<Typography variant="body1">Per Month</Typography>
						</PriceBox>
						<Typography variant="body2">
							Always free and can be upgraded at anytime
						</Typography>
					</ChildMain>
					<ChildBody>
						<InfoBox>
							<Info>
								<CheckIcon />
								<Typography variant="body1">
									1GB Data Download per month
								</Typography>
							</Info>
							
							<Info>
								<CheckIcon />
								<Typography variant="body1">Server side paging</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Record editing</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Community support</Typography>
							</Info>
						</InfoBox>
					</ChildBody>
					<PriceButtonBox>
					<Link to="/signup"><PriceButton variant="outlined">Get Started for free</PriceButton></Link>
					</PriceButtonBox>
				</PricingBodyChild>
				<PricingBodyChild>
					<ChildHead>
						<Typography variant="h5">Standard</Typography>
						<Typography variant="body1">Best value for freelancers</Typography>
					</ChildHead>
					<ChildMain>
						<PriceBox>
							<Typography variant="h4">$12</Typography>
							<Typography variant="body1">Per Month</Typography>
						</PriceBox>
						<Typography variant="body2">
							Billed Monthly or annually
							<br /> with 15% off annual plan rate
						</Typography>
					</ChildMain>
					<ChildBody>
						<InfoBox>
							<Info>
								<CheckIcon />
								<Typography variant="body1">
									10GB Data Download per month
								</Typography>
							</Info>
						

							<Info>
								<CheckIcon />
								<Typography variant="body1">Server side paging</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Record editing</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Entity relationship</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Schema design</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Image preview</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Spatial data preview</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Data visualization</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Email support</Typography>
							</Info>
						</InfoBox>
					</ChildBody>
					<PriceButtonBox>
						<PriceButtonBox>
								<Link to="/signup"><PriceButton variant="outlined">Get Started for free</PriceButton></Link>
						</PriceButtonBox>
					</PriceButtonBox>
				</PricingBodyChild>
				<PricingBodyChild>
					<ChildHead>
						<Typography variant="h5">Team</Typography>
						<Typography variant="body1">Collaborate with team</Typography>
					</ChildHead>
					<ChildMain>
						<PriceBox>
							<Typography variant="h4">$25</Typography>
							<Typography variant="body1">Per Month</Typography>
						</PriceBox>
						<Typography variant="body2">
							Billed Monthly or annually
							<br /> with 20% off annual plan rate
						</Typography>
					</ChildMain>
					<ChildBody>
						<InfoBox>
							<Info>
								<CheckIcon />
								<Typography variant="body1">
									20GB Data Download per month
								</Typography>
							</Info>
						

							<Info>
								<CheckIcon />
								<Typography variant="body1">Server side paging</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Record editing</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Entity relationship</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Schema design</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Image preview</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Spatial data preview</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Data visualization</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Role-based security</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Up to 5 team members</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Email support</Typography>
							</Info>
						</InfoBox>
					</ChildBody>
					<PriceButtonBox>
							<Link to="/signup"><PriceButton variant="outlined">Get Started for free</PriceButton></Link>
					</PriceButtonBox>
				</PricingBodyChild>
				<PricingBodyChild>
					<ChildHead>
						<Typography variant="h5">Enterprise</Typography>
						<Typography variant="body1">
							White label and also available on premise
						</Typography>
					</ChildHead>
					<ChildMain>
						<PriceBox >
							<Typography variant="h4" style={{marginTop: "1.1rem"}}>$1900</Typography>
							<DifText variant="body1" style={{ color: "transparent" }}>
								Per Month
							</DifText>
						</PriceBox>
						<Typography variant="body2">
							Per server, one off. Optional
							<br /> update free at $500/annum
						</Typography>
					</ChildMain>
					<ChildBody>
						<InfoBox>
							<Info>
								<CheckIcon />
								<Typography variant="body1">
									Unlimited transactions per month
								</Typography>
							</Info>
							{/* <Info>
								<CheckIcon />
								<Typography variant="body1">Unlimited records</Typography>
							</Info> */}

							<Info>
								<CheckIcon />
								<Typography variant="body1">Server side paging</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Data editing</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Collaborate with team</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Schema design</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Image preview</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Spatial data preview</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Data visualization</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Role-based security</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Entity realtionship</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Unlimited team members</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Hosted on your server</Typography>
							</Info>
							<Info>
								<CheckIcon />
								<Typography variant="body1">Email and Phone Support</Typography>
							</Info>
						</InfoBox>
					</ChildBody>
					<PriceButtonBox>
							<Link to="/signup"><PriceButton variant="outlined">Get started for free</PriceButton></Link>
					</PriceButtonBox>
				</PricingBodyChild>
			</PricingBody>
		</PricingBox>
	);
};
