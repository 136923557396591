import { useState, useEffect } from "react";
import {
  ActionButton,
  FormBox,
  SelectContainerA,
  SelectContainerB,
  SignupBox,
  SignupChild,
  TextBox,
  Load,
} from "./Signup.style";
import Navbar from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import rectangle from "../../images/Rectangle 116.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

export const Signup = () => {
  const [countries, setCountries] = useState([]);
  const [country, setcountry] = useState("");
  const [cities, setcities] = useState([]);
  const [city, setcity] = useState("");
  const [info, setInfo] = useState({});
  const [isDisabled, setIsdisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [signupInfo, setsignupInfo] = useState({
    country: "",
    city: "",
    email: "",
    password: "",
    retypePassword: "",
    role: "Preview",
  });
  const newUser = {
    country: signupInfo.country,
    city: signupInfo.city,
    email: signupInfo.email,
    password: signupInfo.password,
    retypePassword: signupInfo.retypePassword,
    role: signupInfo.role,
  };
  const [validPassword, setValidPassword] = useState(false);
  const [matchPassword, setMatchPassword] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (country !== "") {
      setIsdisabled(false);
    }
  }, [country]);

  useEffect(() => {}, [isDisabled]);

  useEffect(() => {
    const getCountries = async () => {
      await axios
        .get("https://countriesnow.space/api/v0.1/countries")
        .then((response) => {
          // console.log(response);
          // setCountries(response.data.data);
          let countryName = [];
          setInfo(response.data.data);

          for (let i = 0; i < response.data.data.length; i++) {
            countryName.push(response.data.data[i].country);
          }
          setCountries(countryName);
        })
        .catch((error) => console.log(error));
    };
    getCountries();
  }, []);
  // console.log(countries);
  const countryOptions = countries.map((country, index) => {
    return {
      label: country,
      value: country,
      key: index,
    };
  });
  const cityOptions = cities?.map((city, index) => {
    return {
      label: city,
      value: city,
      key: index,
    };
  });
  const handleCountryChange = (value) => {
    setcountry(value.value);
    // console.log(country);
    setsignupInfo({ ...signupInfo, country: value.value });
  };

  const loadCities = () => {
    // console.log(info);
    // console.log(country);
    let cityArray = [];
    let cty;
    for (let j = 0; j < info.length; j++) {
      if (country === info[j].country) {
        // console.log(info[j].cities);
        cty = info[j].cities;
        cityArray.push(info[j].cities);
      }
    }
    setcities(cty);
    // console.log(cities);
  };

  const handleCitiesChange = (value) => {
    setcity(value.value);
    setsignupInfo({ ...signupInfo, city: value.value });
    console.log(city);
  };
  const handleChange = (e) => {
    setsignupInfo({ ...signupInfo, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (signupInfo.password === signupInfo.retypePassword) {
      console.log(newUser);
      axios
        .post("https://api.flexiblebase.com/api/register", newUser)
        .then((response) => {
          console.log(response);
          console.log("bleh");
          toast.success("Success!, An email has been sent you to activate your account");
          setLoading(false);
          setTimeout(() => {
            navigate("/");
          }
          , 2000);
        })
        .catch((error) => {
          console.log({ error });
          setLoading(false);
          toast.error("An error has occured, try again");
        });
    } else {
      setLoading(false);
      toast.error("The two passwords are not the same");
    }
  };
  return (
    <SignupBox>
      <Navbar />
      <SignupChild>
        <img src={rectangle} alt="rectangle" className="rectangle" />
        <TextBox>
          <Typography variant="h4">Sign Up</Typography>
        </TextBox>
        <FormBox>
          <form action="" onSubmit={handleSubmit}>
            {/* <Typography variant="body1">Fullname</Typography>
						<TextField
							id="fullname"
							label="Enter your fullname"
							variant="filled"
							value={signupInfo.fullname}
							name="fullname"
							onChange={handleChange}
						/>
						<Typography variant="body1">Organization</Typography>
						<TextField
							id="organization"
							label="Enter your organization name"
							variant="filled"
							value={signupInfo.organization}
							name="organization"
							onChange={handleChange}
						/> */}
            <Typography variant="body1">Country</Typography>
            <SelectContainerA
              placeholder="Select country"
              options={countryOptions}
              onChange={handleCountryChange}
              name="countries"
              onBlur={loadCities}
              required
            />
            <Typography variant="body1">City</Typography>
            <SelectContainerB
              isDisabled={isDisabled}
              placeholder="Select city"
              options={cityOptions}
              onChange={handleCitiesChange}
              name="cities"
              required
            />
            <Typography variant="body1">Email</Typography>
            <TextField
              id="email"
              label="Enter your email address"
              variant="filled"
              value={signupInfo.email}
              name="email"
              onChange={handleChange}
              required
            />
            <Typography variant="body1">Password</Typography>
            <TextField
              id="password"
              type="password"
              label="Enter your password"
              variant="filled"
              value={signupInfo.password}
              name="password"
              onChange={handleChange}
              required
              onInput={(e) => {
				 
                setValidPassword(true);
				  
              }}
			  
            />
            <PasswordChecklist
              className={validPassword ? "show" : null}
              rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
              minLength={8}
              value={signupInfo.password}
              // valueAgain={passwordAgain}
              onChange={(isValid) => {}}
            />
            <Typography variant="body1"> Confirm Password</Typography>
            <TextField
              id="retypePassword"
              type="password"
              label="Retype your password"
              variant="filled"
              value={signupInfo.retypePassword}
              name="retypePassword"
              onChange={handleChange}
              required
			  onInput={(e) => {
			
			  setMatchPassword(true);
				
			}}
            />
            <PasswordChecklist
			className={matchPassword ? "view" : null}
              rules={["match"]}
              value={signupInfo.password}
              valueAgain={signupInfo.retypePassword}
              onChange={(isValid) => {}}
            />
            <ActionButton variant="contained" type="submit">
              Sign Up
            </ActionButton>
            <Load loading={loading}>
              <ReactLoading type={"spinningBubbles"} color="#1976D2" />
            </Load>
          </form>
        </FormBox>
      </SignupChild>
      <Footer />
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose="4000"
        hideProgressBar="false"
        closeOnClick
      ></ToastContainer>
    </SignupBox>
  );
};
