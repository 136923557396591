import { useEffect } from "react";
import {
	Aboutbox,
	// Joinbox,
	// Joinchild,
	Main,
	Mainchild,
	Socialbox,
	Socialchild,
	TextBox,
} from "./About.style";
import Navbar from "../../components/navbar/Navbar";
import { Typography } from "@mui/material";
import rectangle from "../../images/Rectangle 116.png";
import rect from "../../images/Rectangle 117.png";
import { Footer } from "../../components/footer/Footer";
export const About = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<Aboutbox>
			<Navbar />
			<Main>
				<img src={rectangle} alt="rectangle" className="rectangle" />
				<TextBox>
					<Typography variant="body1">
						The pricing suits everyone at their capacity
					</Typography>
					<Typography variant="h4">
						We are creating a new future for Databases
					</Typography>
				</TextBox>
				<Mainchild>
					<Typography variant="body2">
						Flexiblebase is a mobile and cloud based enterprise database
						management tool with easy accessibility, flexible data management
						and secure data sharing. It is a modern web based database
						management tool. Flexiblebase speeds up your table design and data
						display, edit, update, and delete without writing a single line of
						SQL statement. Our Overaching goal is to provide tech colutions
						relating to data management and synchronization.
					</Typography>
				</Mainchild>
				{/* <Joinbox>
					<Joinchild>
						<Typography variant="h4">Join Us</Typography>
					</Joinchild>
					<Typography variant="body1">
						Interested in joining us? <a href="/">Go to our Careers page</a>
					</Typography>
				</Joinbox> */}
				<Socialbox>
					<Socialchild>
						<Typography variant="h4">Where to Find Us</Typography>
					</Socialchild>

					<Typography variant="body1">Twitter, LinkedIn, Email</Typography>
				</Socialbox>
				<img src={rectangle} alt="rectangle" className="rect" />
				<img src={rect} alt="rectangle" className="angle" />
			</Main>
			<Footer />
		</Aboutbox>
	);
};
