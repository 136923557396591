import styled from "styled-components";
import Box from "@mui/material/Box";

export const Aboutbox = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

export const Main = styled(Box)`
  width: 85%;
  margin: 5rem auto 12rem;
  text-align: center;
  position: relative;
  .ellipse {
    position: absolute;
    top: 6%;
    left: 50%;
    width: 22%;
  }
  .rectangle {
    width: 50%;
    position: absolute;
    top: -35%;
    right: -45%;
  }
  .rect {
    width: 50%;
    position: absolute;
    bottom: -40%;
    left: -40%;
    z-index: -1;
  }

  .angle {
    width: 50%;
    position: absolute;
    bottom: -60%;
    right: -35%;
    z-index: -1;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1240px) {
    width: 90%;
    .ellipse {
      left: 30%;
      width: 30%;
    }
    .rectangle {
      position: absolute;
      top: -10%;
      right: -70%;
      width: 90%;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: 4rem auto 8rem;
    .ellipse {
      left: 30%;
      width: 40%;
    }
    .rectangle {
      position: absolute;
      top: 0%;
      right: -70%;
      width: 90%;
    }
    .rect {
      bottom: 0;
    }
    .angle {
      bottom: -25%;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .rect {
      bottom: -10%;
    }
    .angle {
      bottom: -10%;
    }
  }
  @media only screen and (max-width: 480px) {
    margin: 2rem auto;
    .ellipse {
      left: 30%;
      width: 50%;
    }
    .rectangle {
      position: absolute;
      top: -10%;
      right: -70%;
      width: 90%;
    }
    .rect {
      width: 90%;
      bottom: 0%;
      left: -50%;
    }
    .angle {
      width: 90%;
      bottom: -20%;
      right: -60%;
    }
  }
`;

export const Mainchild = styled(Box)`
  width: 70%;
  text-align: center;
  margin: 1rem auto 5rem;
  position: relative;
  z-index: 2;
  .MuiTypography-root.MuiTypography-body2 {
    font-size: 1.2rem;
    font-family: "Open Sans", sans-serif;
    color: #7f8da0;
    font-weight: 400;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 80%;
  }
  @media only screen and (max-width: 768px) {
    width: 70%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin: 1rem auto 2rem;
  }
`;
export const TextBox = styled(Box)`
  width: 70%;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .MuiTypography-root.MuiTypography-body1 {
    font-size: 1.5rem;
    font-family: "Open Sans", sans-serif;
    color: #7f8da0;
    font-weight: 400;
    margin: 1rem 0 2rem;
  }
  .MuiTypography-root.MuiTypography-h4 {
    color: #263238;
    font-weight: bold;
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2rem;
    }
  }
`;
export const Joinbox = styled(Box)`
  width: 80%;
  margin: 10rem 0 4rem;
  .MuiTypography-root.MuiTypography-body1 {
    letter-spacing: 0px;
    color: #7f8da0;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: "Open Sans", sans-serif;
  }
  a {
    text-decoration: none;
    color: #0091ea;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-weight: bold;
    font-size: 2.5rem;
  }
  @media only screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`;
export const Joinchild = styled(Box)`
  width: 40%;
  margin: 0 auto 1.5rem;
  padding-left: 2rem;
  text-align: end;

  @media only screen and (max-width: 480px) {
    width: 100%;
    text-align: center;
  }
`;

export const Socialbox = styled(Box)`
  width: 52%;
  margin: 3rem auto 5rem;
  text-align: start;
  & > div:last-of-type {
    width: 35%;
    margin: 0 auto;
    padding: 1rem 0;

    @media only screen and (max-width: 480px) {
      width: 70%;
    }
  }
  .MuiTypography-root.MuiTypography-body1 {
    letter-spacing: 0px;
    color: #0a70fb;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
  }
  @media only screen and (max-width: 768px) {
    width: 70%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin: 3rem auto;
    height: 20vh;
  }
`;

export const Socialchild = styled(Box)`
  width: 75% !important;
  margin: 0 auto 1.5rem;
  padding-left: 2rem;
  text-align: center;
  .MuiTypography-root.MuiTypography-h4 {
    font-weight: bold;
    font-size: 2.5rem;
    margin-left: -2rem;
  }

  @media only screen and (max-width: 480px) {
    width: 100% !important;
    text-align: center;
    padding-left: 0;
    margin-bottom: 1.5rem;
    margin-left: 0;
  }
`;
