import { useEffect } from "react";
import { Footer } from "../../components/footer/Footer";
import { Landing } from "../../components/landing/Landing";
import { IndexBox } from "./Indexpage.style";
import bg from "../../images/Landing diet – 2.png";

export const Indexpage = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<IndexBox img={bg} className="landing">
			<Landing />
			<Footer />
		</IndexBox>
	);
};
