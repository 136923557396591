import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const PricingBox = styled(Box)`
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
`;

export const Main = styled(Box)`
	width: 100%;
	margin: 5rem auto 2rem;
	position: relative;
	&>div: last-of-type {
		width: 100vw !important;
	}

	.ellipse {
		position: absolute;
		top: 1.5%;
		left: 50%;
		width: 20%;
	}
	.rectangle {
		position: absolute;
		top: -10%;
		right: -80%;
	}
	.rect {
		position: absolute;
		bottom: -20%;
		left: -50%;
		z-index: -1;
	}
	.angle {
		width: 60%;
		position: absolute;
		bottom: -20%;
		right: -30%;
		z-index: -1;
	}
	@media only screen and (min-width: 1024px) and (max-width: 1240px) {
		width: 90%;
		.ellipse {
			left: 30%;
			width: 40%;
		}
		.rectangle {
			position: absolute;
			top: -10%;
			right: -90%;
			width: 90%;
		}
		.rect {
			width: 90%;
			bottom: -5%;
			left: -70%;
		}
		.angle {
			bottom: -10%;
		}
	}
	@media only screen and (min-width: 769px) and (max-width: 1023px) {
		width: 100%;
		.ellipse {
			left: 30%;
			width: 50%;
		}
		.rectangle {
			position: absolute;
			top: -5%;
			right: -80%;
			width: 90%;
		}
		.rect {
			width: 90%;
			bottom: -7% !important;
			left: -70%;
		}
		.angle {
			bottom: -10%;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 100%;
		.ellipse {
			left: 30%;
			width: 50%;
		}
		.rectangle {
			position: absolute;
			top: -5%;
			right: -80%;
			width: 90%;
		}
		.rect {
			width: 90%;
			bottom: 0%;
			left: -70%;
		}
		.angle {
			bottom: -10%;
		}
	}
	@media only screen and (max-width: 480px) {
		width: 95%;
		margin: 2rem auto;
		.ellipse {
			left: 30%;
			width: 50%;
		}
		.rectangle {
			position: absolute;
			top: -3%;
			right: -70%;
			width: 90%;
		}
	}
`;

export const MiniChild = styled(Box)`
	width: 85%;
	margin: 3rem auto 6rem;
`;

export const Mainchild = styled(Box)`
	width: 100%;
	text-align: center;
	margin-bottom: 5rem;
`;
export const TextBox = styled(Box)`
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 2;
	.MuiTypography-root.MuiTypography-body1 {
		letter-spacing: 0px;
		color: #7f8da0;
		font-family: "Open Sans", sans-serif;
		font-size: 1.2rem;
		margin: 1rem 0;
	}
	.MuiTypography-root.MuiTypography-h4 {
		display: inline-block;
		font-size: 3rem;
		font-family: "Open Sans", sans-serif;
		color: #23233c;
		font-weight: bold;
		margin-bottom: 3rem;
		.MuiTypography-root.MuiTypography-h4 {
			color: #0091ea;
			font-family: "Open Sans", sans-serif;
		}
	}
	.MuiTypography-root.MuiTypography-body2 {
		font-size: 1rem;
		letter-spacing: 0px;
		color: #7f8da0;
		font-family: "Open Sans", sans-serif;
		font-size: 1.2rem;
		margin: 1rem 0;
	}
	@media only screen and (max-width: 640px) {
		width: 100%;
		margin: 1rem 0;
	}
	@media only screen and (max-width: 768px) {
		.MuiTypography-root.MuiTypography-body1 {
			font-size: 1rem;
			margin: 0 0 0.5rem 0;
		}
		.MuiTypography-root.MuiTypography-h4 {
			font-size: 2.5rem;
			margin: 0;
		}
		.MuiTypography-root.MuiTypography-body2 {
			word-spacing: 0.7px;
			font-size: 0.8rem;
		}
	}
	@media only screen and (max-width: 768px) {
		.MuiTypography-root.MuiTypography-body1 {
			font-size: 1rem;
			margin: 0 0 0.5rem 0;
		}
		.MuiTypography-root.MuiTypography-h4 {
			font-size: 2rem;
			margin: 0;
		}
		.MuiTypography-root.MuiTypography-body2 {
			word-spacing: 0.7px;
			font-size: 0.8rem;
		}
	}
`;

export const MainButtonBox = styled(Box)`
	width: 35%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin: 2rem auto 0;
	.MuiButtonBase-root.MuiButton-root {
		width: 40%;
		font-size: 0.8rem;
		font-weight: 600;
		text-transform: none;
		padding: 0.7rem 0;
		margin: 0.4rem 0;
		img {
			width: 15%;
			margin-right: 0.3rem;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 80%;
		.MuiButtonBase-root.MuiButton-root:last-of-type {
			width: 60%;
		}
	}
`;

export const MainButton = styled(Button)`
	a {
		text-decoration: none;
		color: #ffffff;
	}
`;
