import {
	ImageBox,
	SectionBody,
	SectionBodyChildA,
	SectionBodyChildB,
	SectionBox,
	SectionHead,
	SectionHeadChild,
	TypographyContainer,
} from "./Reverse.style";
// import line from "../../images/Line 4.png";
// import ellipse from "../../images/Ellipse 66.png";
import rect from "../../images/Rectangle 335.png";
import Typography from "@mui/material/Typography";
export const Reverse = (props) => {
	return (
		<SectionBox>
			<SectionHead>
				<SectionHeadChild>
					{/* <img src={line} alt="divider" /> */}
					<Typography variant="h4">
						{props.heading1}{" "}
						<Typography variant="h4">{props.heading2}</Typography>
					</Typography>
				</SectionHeadChild>
			</SectionHead>
			<SectionBody>
				<SectionBodyChildB>
					<img src={rect} alt="rect" className="rect" />
					<TypographyContainer>
						<Typography variant="body1">{props.text1}</Typography>
						<Typography variant="body1">{props.text2}</Typography>
					</TypographyContainer>
				</SectionBodyChildB>
				<SectionBodyChildA>
					{/* <img src={ellipse} alt="ellipse" className="ellipse" /> */}
					<ImageBox>
						<img src={props.image} alt="database table" />
					</ImageBox>
				</SectionBodyChildA>
			</SectionBody>
		</SectionBox>
	);
};
