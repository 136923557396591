import { useEffect } from "react";
import {
	WhyBox,
	Main,
	// MainButton,
	// MainButtonBox,
	Mainchild,
	TextBox,
	TextBox2,
} from "./Why.style";
// import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Typography from "@mui/material/Typography";
// import line from "../../images/Line 4.png";
import rectangle from "../../images/Rectangle 116.png";
// import play from "../../images/play-button-svgrepo-com.png";
import { Footer } from "../../components/footer/Footer";
export const Why = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<WhyBox>
			<Navbar />
			<Main>
				<Mainchild>
					<img src={rectangle} alt="rectangle" className="rectangle" />
					<TextBox>
						<Typography variant="body1">
							It is possible to work on databases, without knowledge of SQL with{" "}
							<strong>Flexiblebase</strong>
						</Typography>
						<Typography variant="h4">
							Why <Typography variant="h4">Flexiblebase?</Typography>
						</Typography>
						<div>
							<Typography variant="body2">
								One of the challenges software developers when away from their
								laptop is the inability to work on databases from their mobile
								devices or another laptop.{" "}
								<strong>
									This was what led to the design of Flexiblebase.
								</strong>
							</Typography>
						</div>
					</TextBox>
					{/* <MainButtonBox>
						<MainButton
							variant="contained"
							style={{ backgroundColor: "#1283EDA6" }}
						>
							Live Demo
						</MainButton>
						<MainButton
							variant="contained"
							style={{ backgroundColor: "#1283ED" }}
						>
							<Link to="/signup">Sign Up</Link>
						</MainButton>
						<MainButton variant="outlined">
							<img src={play} alt="play" />
							Watch Illustration
						</MainButton>
					</MainButtonBox> */}
					<TextBox2>
						<Typography variant="body2">
							<strong>Another reason</strong> is that many teams work on schema
							design and development, Sql data analysis, html form design, data
							entry, entity relationship, data migration etc. And there are
							several tools for doing these. But, <strong>Flexiblebase</strong>{" "}
							provides a real-time platform for team collaboration to visualise
							schema design and persistent table creation on the database, and
							also analyse data.
						</Typography>
					</TextBox2>
				</Mainchild>
			</Main>
			<Footer />
		</WhyBox>
	);
};
